/* eslint-disable prettier/prettier */
import { Background, getPropBasedBackgroundRules } from '@/components/Background';
import { isLocale, languages } from '@/contexts/LanguageContext';
import { withRouterQuery } from '@/utils';
import * as Sentry from '@sentry/nextjs';
import { LayoutContextProvider } from 'contexts/LayoutContext/LayoutContext';
import { AppProps } from 'next/app';
import ErrorPage from 'next/error';
import Head from 'next/head';
// Todo: Figure out Postcss-easy-import
import 'styles/base.css';
import 'styles/fonts.css';
import 'styles/Navigation.css';
import 'styles/RangeInput.css';
import 'styles/Slider.css';
import 'styles/Toggle.css';
import 'styles/Banner.css';
import 'styles/leaflet.css';
import 'styles/scrollbar.css';
import { SeoProps } from 'types';
import { useGitlabVisualReview } from '@/hooks/useGitlabVisualReview';
import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react';
import { LearningModeContextProvider } from '@/contexts/LearningModeContext/LearningModeContext';
import PerformanceTracer from '@/components/PerformanceTracer';
import Script from 'next/script';
import { SettingsContextProvider } from '@/contexts/SettingsContext';
import { useEffect, useState } from 'react';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: process.env.NEXT_PUBLIC_COMMIT_SHA,
    environment: process.env.NEXT_PUBLIC_CI_COMMIT_BRANCH,
  });
}

export let matomoSiteID: number = parseInt(process.env.NEXT_PUBLIC_MATOMO_SITE_ID || '4');

// Make matomoSiteID available globally for Klaro
if (typeof window !== 'undefined') {
  window.matomoSiteID = matomoSiteID;
}

const defaultSeoObj = {
  title: 'Herzlich Willkommen beim Jobcenter Dortmund',
  seoSnippet:
    'Die gemeinsame Einrichtung Jobcenter Dortmund ist ein großer sozialer Arbeitsmarktdienstleister in der Stadt Dortmund. Die Bundesagentur für Arbeit und die Stadt Dortmund stehen als Träger des Jobcenters für eine gelungene Zusammenarbeit.',
  keywords:
    'Jobcenter, Jobcenter Dortmund, SGB2, SGB II, Arbeitsuchende Dortmund, Hilfen Dortmund, Dortmund arbeitslos, Arbeitssuchend Dortmund, Leistungen Dortmund, Hartz 4 Dortmund, Hartz 4, Dortmund, Geldsicherung, Grundsicherung Dortmund, Arbeitgeber Dortmund, Downloadcenter, Anträge, Formulare, stadt Dortmund, Maßnahmeträger Dortmund, Bürgerinnen und Bürger in Dortmund ',
};

type AdditionalAppProps = {
  pageProps: { [key: string]: any } & SeoProps;
  err: Error;
};

type ComponentProps = {
  err: Error;
};

export default function App({ Component, pageProps, err, router }: Omit<AppProps<ComponentProps>, 'pageProps'> & AdditionalAppProps) {
  const [matomoInstance, setMatomoInstance] = useState(() => 
    createInstance({
      urlBase: 'https://matomo.jobcenterdortmund.de/',
      siteId: matomoSiteID,
      trackerUrl: 'https://matomo.jobcenterdortmund.de/matomo.php',
      srcUrl: '/static/scripts/matomo.js',
      disabled: true, // Start disabled by default
      heartBeat: { active: false },
      linkTracking: false,
      configurations: {
        disableCookies: true,
        setSecureCookie: matomoSiteID === 1,
        setRequestMethod: 'POST',
      },
    })
  );

  useEffect(() => {
    const checkConsent = () => {
      const klaroManager = window.klaro?.getManager?.();
      const matomoConsent = klaroManager?.consents?.matomo;
      
      setMatomoInstance(createInstance({
        urlBase: 'https://matomo.jobcenterdortmund.de/',
        siteId: matomoSiteID,
        trackerUrl: 'https://matomo.jobcenterdortmund.de/matomo.php',
        srcUrl: '/static/scripts/matomo.js',
        disabled: !matomoConsent,
        heartBeat: { active: false },
        linkTracking: false,
        configurations: {
          disableCookies: !matomoConsent,
          setSecureCookie: matomoSiteID === 1,
          setRequestMethod: 'POST',
        },
      }));
    };

    checkConsent();
    window.addEventListener('klaro:consent-change', checkConsent);
    return () => window.removeEventListener('klaro:consent-change', checkConsent);
  }, []);

  useGitlabVisualReview();

  const { lang } = router.query;

  if (process.env.NODE_ENV === 'production') {
    if (!isLocale(lang) && router.pathname !== '/') {
      return (
        <>
          <Head>
            <meta name="robots" content="noindex" />
          </Head>
          <ErrorPage statusCode={404} />
        </>
      );
    }
  }

  const { title, seoSnippet, keywords } = Object.entries(pageProps).reduce((acc, [key, val]) => {
    if (val) return { ...acc, [key]: val };
    return acc;
  }, defaultSeoObj);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width,initial-scale=1,shrink-to-fit=no" />
        <title>Jobcenter Dortmund | {title}</title>
        <meta name="title" content={title} />
        <meta property="og:title" content={title} />
        <meta property="twitter:title" content={title} />
        {languages.map((lang) => (
          <link rel="alternate" key={`link-${lang}`} hrefLang={lang} href={withRouterQuery(router.pathname, { ...router.query, lang })} />
        ))}
        <link rel="alternate" hrefLang="x-default" href={withRouterQuery(router.pathname, { ...router.query, lang: 'de' })} />
        <meta name="description" content={seoSnippet} />
        <meta property="og:description" content={seoSnippet} />
        <meta property="twitter:description" content={seoSnippet} />
        <meta name="keywords" content={keywords} />
        <meta property="og:url" content={`https://jobcenterdortmund.de${withRouterQuery(router.pathname, router.query)}`} />
        <meta property="twitter:url" content={`https://jobcenterdortmund.de${withRouterQuery(router.pathname, router.query)}`} />
      </Head>
      <MatomoProvider value={matomoInstance}>
        <PerformanceTracer>
          <LayoutContextProvider router={router}>
            <SettingsContextProvider>
              <LearningModeContextProvider>
                <Background customRules={getPropBasedBackgroundRules(pageProps)} />
                <Component {...pageProps} err={err} />
              </LearningModeContextProvider>
            </SettingsContextProvider>
          </LayoutContextProvider>
        </PerformanceTracer>
      </MatomoProvider>
    </>
  );
}
